import React from 'react';
import { graphql } from 'gatsby';
import { get } from 'lodash';
import { getImage } from 'gatsby-plugin-image';
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3';
import { Modal } from '../../components/Modal';
import { Seo } from '../../components/SEO';
import { StyledModal } from './styles';

interface IModalPageTemplate {
  content: any;
  location: any;
}

interface IModalPage {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
    contentfulModal: any;
  };
  pageContext: any;
  location: any;
}

const ModalPageTemplate = ({ content, location }: IModalPageTemplate) => {
  const {
    title,
    subtitle,
    image: modalImage,
    video,
    imageAlt,
    imageDescription,
    next,
    prev,
  } = content;
  const { pathname } = location;
  const path = pathname.replace(/\/[^/]+$/, ``);
  const image = getImage(modalImage);
  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => {
        return (
          <Modal
            isModal={modal}
            closeTo={closeTo}
            title={title}
            subtitle={subtitle}
            imageAlt={imageAlt}
            image={image}
            video={video}
            imageDescription={imageDescription}
            next={next && next.slug ? `${path}/${next.slug}` : null}
            prev={prev && prev.slug ? `${path}/${prev.slug}` : null}
          />
        );
      }}
    </ModalRoutingContext.Consumer>
  );
};

const ModalPage = ({ data, pageContext, location }: IModalPage) => {
  const modal = get(data, `contentfulModal`);
  const { next, prev } = pageContext;
  const {
    templateKey,
    title,
    subtitle,
    slug,
    metaTitle,
    metaDescription,
    image: modalImage,
    video,
    imageDescription,
  } = modal;
  let imageAlt;
  let fluid;
  let image;
  if (modalImage) {
    imageAlt = modalImage.description;
    fluid = modalImage.fluid;
    image = getImage(modalImage);
  }
  return (
    <StyledModal>
      <Seo
        title={title}
        metaTitle={metaTitle}
        metaDesc={metaDescription}
        cover={fluid}
        slug={slug}
        templateKey={templateKey}
      />
      <ModalPageTemplate
        content={{
          title,
          subtitle,
          image,
          video,
          imageAlt,
          imageDescription,
          next,
          prev,
        }}
        location={location}
      />
    </StyledModal>
  );
};

export default ModalPage;

export const ModalPageQuery = graphql`
  query ModalPage($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulModal(slug: { eq: $slug }) {
      templateKey
      title
      subtitle
      slug
      metaTitle
      metaDescription
      image {
        description
        gatsbyImageData(layout: FULL_WIDTH, quality: 85, placeholder: BLURRED)
      }
      video
      imageDescription
    }
  }
`;
