import styled from 'styled-components';
import { rem } from 'polished';

interface IStyledModalContainer {
  isModal: any;
}

interface IStyledModal {
  isModal: any;
}

export const StyledModalContainer = styled.div<IStyledModalContainer>`
  display: block;
  width: 100%;
  height: ${({ isModal }) => (isModal ? `100%` : `100vh`)};
  background-color: ${({ theme, isModal }) =>
    isModal ? `initial` : theme.colors.blue_1};
  .modal__link-prev,
  .modal__link-next,
  .modal__link-back {
    width: ${rem(`48px`)};
    height: ${rem(`48px`)};
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    color: ${({ theme }) => theme.colors.white_1};
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.blue_1_65};
    transition: all 500ms ease-in-out;
    margin: 3px;
    ${({ theme }) => theme.breakpoints.tablet`
      :hover {
        color: ${theme.colors.pink_1};
        background: ${theme.colors.blue_1_35};
      }
    `}
  }
  .modal__link-back {
    left: 0;
    top: 0;
  }
  .modal__link-prev,
  .modal__link-next {
    top: 50%;
    transform: translate(0, -50%);
  }
  .modal__link-prev {
    left: 0;
  }
  .modal__link-next {
    right: 0;
  }
`;

export const StyledModal = styled.div<IStyledModal>`
  display: flex;
  width: calc(100% - ${rem(`132px`)});
  max-width: ${({ isModal }) => (isModal ? `100%` : `1030px`)};
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
  background: ${({ theme }) => theme.colors.blue_1};
  .modal__content {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: ${rem(`24px`)} ${rem(`16px`)};
  }
  .modal__image {
    border-radius: ${rem(`12px`)};
  }
  .modal__title {
    flex: 1 0 100%;
  }
  .modal__subtitle {
    flex: 1 0 100%;
  }
  .modal__description {
    flex: 1 0 100%;
  }
  .modal__button-container {
    flex: 1 0 100%;
    display: flex;
    justify-content: center;
  }
  .modal__button-link {
    text-decoration: none;
  }
  .modal__button {
    margin: 0 auto;
    border-width: 0;
  }
`;
