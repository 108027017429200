import React from 'react';
import ReactPlayer from 'react-player';
import { StyledVideo } from './styles';

interface IVideoPlayer {
  className?: string;
  url: string;
}

export const VideoPlayer = ({ className, url }: IVideoPlayer) => {
  return (
    <StyledVideo className={className}>
      {url && (
        <ReactPlayer
          className="react-player"
          url={url}
          width="100%"
          height="100%"
          controls
          playing
        />
      )}
    </StyledVideo>
  );
};
