import React from 'react';
import { navigate } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby-plugin-modal-routing'; // tslint:disable-line
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faChevronCircleLeft,
  faChevronCircleRight,
} from '@fortawesome/pro-light-svg-icons';
import { Text } from '../Text';
import { StyledModalContainer, StyledModal } from './styles';
import { Button } from '../Button';
import { VideoPlayer } from '../VideoPlayer';

interface IModal {
  className?: string;
  isModal: any;
  closeTo?: string;
  title?: string;
  subtitle?: string;
  imageAlt?: string;
  image?: any;
  imageDescription?: string;
  video?: string;
  next?: any;
  prev?: any;
}

export const Modal = ({
  className,
  isModal,
  closeTo,
  title,
  subtitle,
  imageAlt,
  image,
  imageDescription,
  video,
  next,
  prev,
}: IModal) => {
  const handleNext = () => navigate(next, { state: { modal: true } });
  const handlePrevt = () => navigate(prev, { state: { modal: true } });

  return (
    <StyledModalContainer isModal={isModal}>
      {isModal && prev ? (
        <button
          type="button"
          onClick={handlePrevt}
          className="modal__link-prev"
        >
          <FontAwesomeIcon size="2x" icon={faChevronCircleLeft} />
        </button>
      ) : null}
      {isModal && next ? (
        <button type="button" onClick={handleNext} className="modal__link-next">
          <FontAwesomeIcon size="2x" icon={faChevronCircleRight} />
        </button>
      ) : null}
      {isModal ? (
        <Link
          aria-label="close modal"
          to={closeTo}
          className="modal__link-back"
        >
          <FontAwesomeIcon size="2x" icon={faTimes} />
        </Link>
      ) : null}
      <StyledModal isModal={isModal} className={className}>
        {isModal ? null : (
          <div className="modal__content">
            <Text
              noRhythem
              type="title1"
              tag="h1"
              color="white_1"
              className="modal__header-default"
            >
              Domusnetwork.io
            </Text>
          </div>
        )}

        {image && (
          <GatsbyImage image={image} alt={imageAlt} className="modal__image" />
        )}
        {video && <VideoPlayer url={video} />}
        {isModal ? null : (
          <div className="modal__content">
            <Text
              noRhythem
              type="title2"
              tag="h2"
              color="white_1"
              className="modal__title"
            >
              {title}
            </Text>
            <Text
              noRhythem
              type="subtitle1"
              tag="h3"
              color="white_1_45"
              className="modal__subtitle"
            >
              {subtitle}
            </Text>
            <Text
              noRhythem
              type="body1"
              tag="p"
              color="white_1"
              className="modal__description"
            >
              {imageDescription}
            </Text>
            <div className="modal__button-container">
              <Link to="/projects/" className="modal__button-link">
                <Button size="large" disabled={false} className="modal__button">
                  Back to projects
                </Button>
              </Link>
            </div>
          </div>
        )}
      </StyledModal>
    </StyledModalContainer>
  );
};
